import React, { useContext, useEffect } from 'react'
import { NextSeo } from 'next-seo'
import { useTranslation } from 'next-i18next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import UserContext from '../components/UserContext'
import Home from '../components/home/home'
import Meta from '../components/Meta'

import { fetchGetTotalMiningSteps, fetchGetServiceStatistic } from '../utils/client/mining-statistic-api'
import { fetchGetRecentMining } from '../utils/client/mining-api'

export default function Index(props) {
  const { t } = useTranslation('common')
  const { scrollRef } = useContext(UserContext)

  useEffect(() => {
    window.scrollTo(0, 0)
    const handleScrollPos = () => {
      scrollRef.current.scrollPos = window.scrollY
    }
    window.addEventListener('scroll', handleScrollPos)
    return () => {
      window.removeEventListener('scroll', handleScrollPos)
    }
  })

  return (
    <>
      <Meta title={t('site_name')} keyword={t('keywords')} desc={t('description')} />
      <NextSeo
        title={t('site_name')}
        description={t('description')}
        openGraph={{
          title: t('site_name'),
          siteName: t('site_name'),
          description: t('description'),
          article: {
            authors: [t('app_name')],
          },
        }}
      />
      <Home
        totalSteps={props.totalSteps}
        recentMiningList={props.recentMiningList}
        serviceStatistic={props.serviceStatistic}
      />
    </>
  )
}

export async function getServerSideProps(ctx) {
  const isSSR = ctx.req !== null && ctx.req !== undefined

  const recentMiningResponse = await fetchGetRecentMining(isSSR)
  const totalStepsResponse = await fetchGetTotalMiningSteps(isSSR)
  const serviceStatisticResponse = await fetchGetServiceStatistic(isSSR)

  let recentMiningList = []
  let serviceStatistic = undefined
  let totalSteps = undefined

  if (totalStepsResponse && totalStepsResponse.status === 200) {
    totalSteps = totalStepsResponse.data.totalSteps
  }

  if (recentMiningResponse && recentMiningResponse.status === 200) {
    recentMiningList = recentMiningResponse.data
  }

  if (serviceStatisticResponse && serviceStatisticResponse.status === 200) {
    serviceStatistic = serviceStatisticResponse.data
  }

  // 걸음 수 조회
  return {
    props: {
      isSSR,
      totalSteps,
      recentMiningList,
      serviceStatistic,
      ...(await serverSideTranslations(ctx.locale, ['common', 'index'])),
    },
  }
}
