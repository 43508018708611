import React from 'react'
import { TopMiningCollection } from '../../components/component'
import Hero from '../../components/hero/hero'
import MiningTable from '../../components/mining'
import RecentGameTable from '../../components/game'
import Download from '../../components/download/download'
import Statistic from '../../components/promo/statistic'
// import PromotionBanner from '../promotion/PromotionBanner'

const Home = ({ recentMiningList, totalSteps, serviceStatistic }) => {
  return (
    <>
      <Hero totalSteps={totalSteps} />
      {/* <PromotionBanner /> */}
      <MiningTable recentMiningList={recentMiningList} totalMiningCount={serviceStatistic?.totalMiningCount} />
      <RecentGameTable mode="WINNERS" viewSize={10} moreBtn={true} />
      <TopMiningCollection bgWhite={true} />
      <Statistic serviceStatistic={serviceStatistic} />
      <Download />
    </>
  )
}

export default Home
