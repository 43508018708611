import { useState, useMemo, useEffect } from 'react'
import Link from 'next/link'
import CountUp from 'react-countup'
import TextTransition, { presets } from 'react-text-transition'
import debounce from 'lodash/debounce'
import { useTranslation } from 'next-i18next'
import { getSearchResultLinkPath, numberWithCommasSteps } from '../../utils/utils'
import { useInterval } from '../../utils/useInterval'
import { fetchGetTotalMiningSteps, fetchGetLastMinner } from '../../utils/client/mining-statistic-api'
import { fetchSearch } from '../../utils/client/search-api'
import Loading from '../Loading'
import { getAvatar } from '../../utils/avatar'

const Hero = ({ totalSteps }) => {
  const [inputSearchValue, setInputSearchValue] = useState('')
  const [searchResult, setSearchResult] = useState([])
  const [isSearchLoading, setIsSearchLoading] = useState(false)
  const [currentTotalSteps, setCurrentTotalSteps] = useState(totalSteps)
  const [nextTotalSteps, setNextTotalSteps] = useState(totalSteps)
  const [currentRecentMinner, setCurrentRecentMinner] = useState(undefined)
  const [recentMinner, setRecentMinner] = useState(undefined)

  const { t: tCommon } = useTranslation('common')

  // API 응답값으로 갱신
  async function updateTotalStep() {
    const newTotalSteps = await fetchGetTotalMiningSteps()
    if (newTotalSteps && newTotalSteps.status === 200) {
      const newSteps = newTotalSteps.data.totalSteps - currentTotalSteps
      setCurrentTotalSteps(nextTotalSteps)
      setNextTotalSteps(currentTotalSteps + newSteps)
    }
  }

  async function updateLastMinner() {
    const newLastMinner = await fetchGetLastMinner()
    if (newLastMinner && newLastMinner.status === 200 && newLastMinner.data !== '') {
      setCurrentRecentMinner(newLastMinner.data)
      setRecentMinner(`@${newLastMinner.data.userName} ${newLastMinner.data.amount} WKM`)
    }
  }

  const handleInputSearchValue = (e) => {
    if (!e || e.trim().length === 0) {
      setSearchResult([])
    } else {
      debounceSearch(e)
    }
    setInputSearchValue(e)
  }

  const handleSearchKeyPress = (e) => {
    if (e.key === 'Enter') {
      search(inputSearchValue)
    }
  }

  async function search(q) {
    setIsSearchLoading(true)
    const response = await fetchSearch(q)
    if (response && response.status === 200 && response.data) {
      setSearchResult(response.data)
    } else {
      setSearchResult([])
    }
    setIsSearchLoading(false)
  }

  const debounceSearch = useMemo(
    () =>
      debounce((e) => {
        search(e)
      }, 500),
    [],
  )

  useEffect(() => {
    updateLastMinner()
  }, [])

  useInterval(() => {
    updateTotalStep()
    updateLastMinner()
  }, 5000)

  return (
    <>
      {/* <!-- Hero --> */}
      <section className="hero relative py-20 md:pt-32">
        <picture className="pointer-events-none absolute inset-x-0 top-0 -z-10 dark:hidden">
          <img src="/images/gradient.jpg" alt="gradient" className="h-full w-full" />
        </picture>
        <picture className="pointer-events-none absolute inset-x-0 top-0 -z-10 hidden dark:block">
          <img src="/images/gradient_dark.jpg" alt="gradient dark" className="h-full w-full" />
        </picture>

        <div className="container">
          <div className="mx-auto max-w-2xl pt-24 text-center">
            <h1 className="mb-2 text-4xl text-jacarta-700 dark:text-white lg:text-5xl xl:text-6xl">
              {tCommon('app_name')}
            </h1>
            <div className="mb-10 text-5xl">
              {totalSteps && (
                <div>
                  <CountUp
                    start={currentTotalSteps}
                    end={nextTotalSteps}
                    duration={2}
                    formattingFn={(n) => numberWithCommasSteps(n, tCommon('steps'))}
                  >
                    {({ countUpRef }) => (
                      <div>
                        <span className="animate-gradient" ref={countUpRef} />
                      </div>
                    )}
                  </CountUp>
                </div>
              )}
            </div>

            {/* <!-- Search --> */}
            <div className="relative mb-4">
              <input
                type="search"
                className="w-full rounded-2xl border border-jacarta-100 py-4 px-4 pl-10 text-sm text-jacarta-700 placeholder-jacarta-300 focus:ring-accent dark:border-transparent dark:bg-white/[.15] dark:text-white dark:placeholder-white"
                placeholder="Search by User, NFT, World"
                value={inputSearchValue}
                onChange={(e) => {
                  handleInputSearchValue(e.target.value)
                }}
                onKeyUp={handleSearchKeyPress}
              />
              <span className="absolute left-0 top-0 flex h-full w-12 items-center justify-center rounded-2xl">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  className="h-4 w-4 fill-jacarta-500 dark:fill-white"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M18.031 16.617l4.283 4.282-1.415 1.415-4.282-4.283A8.96 8.96 0 0 1 11 20c-4.968 0-9-4.032-9-9s4.032-9 9-9 9 4.032 9 9a8.96 8.96 0 0 1-1.969 5.617zm-2.006-.742A6.977 6.977 0 0 0 18 11c0-3.868-3.133-7-7-7-3.868 0-7 3.132-7 7 0 3.867 3.132 7 7 7a6.977 6.977 0 0 0 4.875-1.975l.15-.15z" />
                </svg>
              </span>

              {isSearchLoading && (
                <span className="absolute right-0 top-0 flex h-full w-12 items-center justify-center rounded-2xl">
                  <Loading />
                </span>
              )}
            </div>
            {inputSearchValue && inputSearchValue.length > 0 && searchResult && searchResult.length > 0 && (
              <div className="overflow-scroll h-[200px] pb-6">
                {searchResult.map((item, idx) => {
                  return (
                    <div
                      key={idx}
                      className="mb-1 border-jacarta-100 dark:bg-jacarta-700 rounded-2xl flex border bg-white py-4 px-7 transition-shadow hover:shadow-lg dark:border-transparent"
                    >
                      <div>
                        <Link legacyBehavior href={getSearchResultLinkPath(item.resultType, item.targetValue)}>
                          <span className="block cursor-pointer">
                            {item.resultType === 'USER' && (
                              <img className="h-8 w-8 inline-block mr-1" src={getAvatar(item.profileImage)} />
                            )}
                            <span className="font-display text-jacarta-700 hover:text-accent font-semibold dark:text-white">
                              {item.title}
                            </span>
                          </span>
                        </Link>
                      </div>
                    </div>
                  )
                })}
              </div>
            )}
            {recentMinner && currentRecentMinner && (
              <Link legacyBehavior href={`/user/${currentRecentMinner.referralCode}`}>
                <a className="text-accent hover:text-jacarta-700 dark:hover:text-white inline-block pl-1">
                  <TextTransition springConfig={presets.wobbly}>{recentMinner}</TextTransition>
                </a>
              </Link>
            )}
          </div>
        </div>
      </section>
      {/* <!-- end hero --> */}
    </>
  )
}

export default Hero
