import { http, responseOk, responseError } from './axios-client'
import _ from 'lodash'

export async function fetchGetServiceStatistic(isSSR = false) {
  return await http(isSSR)
    .get(`explorer/statistic`)
    .then((res) => responseOk(res))
    .catch((err) => responseError(err))
}

export async function fetchGetTotalMiningSteps(isSSR = false) {
  return await http(isSSR)
    .get(`explorer/statistic/total-steps`)
    .then((res) => responseOk(res))
    .catch((err) => responseError(err))
}

export async function fetchGetLastMinner(isSSR = false) {
  return await http(isSSR)
    .get(`explorer/statistic/last-minner`)
    .then((res) => responseOk(res))
    .catch((err) => responseError(err))
}
