import FadeIn from 'react-fade-in'
import MiningItem from './MiningItem'

function MiningTableList({ recentMinings }) {
  return recentMinings ? (
    <div className="divide-y divide-jacarta-100 dark:divide-jacarta-600">
      <FadeIn>
        {recentMinings.map((mining, idx) => {
          return (
            <MiningItem
              key={idx}
              hash={mining.hash}
              minner={mining.userName}
              referralCode={mining.referralCode}
              quantity={mining.amount}
              steps={mining.step}
              symbol={'WKM'}
              time={mining.dateTime}
            />
          )
        })}
      </FadeIn>
    </div>
  ) : null
}
export default MiningTableList
