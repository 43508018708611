import Link from 'next/link'

const RecentGameUserItem = ({
  gameName,
  userName,
  referralCode,
  buyAmount,
  buyTokenType,
  winningAmount,
  prizeType,
  dateTime,
}) => {
  var image = undefined
  var winPrizeImage = undefined
  if (buyTokenType === 'WKM') {
    image = 'https://ad-banner.walkmining.com/web/ic_coin_wkm.png'
  } else if (buyTokenType === 'WKG') {
    image = 'https://ad-banner.walkmining.com/web/ic_coin_wkg.png'
  } else if (buyTokenType === 'ENERGY') {
    image = 'https://global-images.walkmining.com/explorer/ic_energe.png'
  } else {
    image = undefined
  }

  if (prizeType === 'WKM') {
    winPrizeImage = 'https://ad-banner.walkmining.com/web/ic_coin_wkm.png'
  } else if (prizeType === 'WKG') {
    winPrizeImage = 'https://ad-banner.walkmining.com/web/ic_coin_wkg.png'
  } else if (prizeType === 'ENERGY') {
    winPrizeImage = 'https://global-images.walkmining.com/explorer/ic_energe.png'
  } else {
    winPrizeImage = undefined
  }

  return (
    <div className="flex crypto-price items-center">
      <div className="crypto-price__coin flex w-[25%] items-center px-3 py-5">
        <div className="crypto-price__name flex-1 text-sm font-display font-semibold">
          <span className="text-jacarta-700 dark:text-white mr-3">#{gameName}</span>
        </div>
      </div>
      <div className="crypto-price__coin flex w-[25%] px-3 py-5 items-center text-sm cursor-pointer">
        <Link legacyBehavior href={`/user/${referralCode}`}>
          <span>@{userName}</span>
        </Link>
      </div>
      {!winningAmount && !prizeType && (
        <div className="crypto-price__coin flex w-[25%] px-3 py-5 items-center">
          {buyAmount === 0 ? (
            <span>FREE</span>
          ) : (
            <>
              {image && <img src={image} className="mr-2" width="24" height="24" alt="Play Token" />}
              <div className="crypto-price__name flex-1 text-sm font-display font-semibold">
                <span className="text-jacarta-700 dark:text-white mr-1">{buyAmount}</span>
                {!image && <span className="text-[10px]">{buyTokenType}</span>}
              </div>
            </>
          )}
        </div>
      )}
      {winningAmount && prizeType && (
        <div className="crypto-price__coin flex w-[25%] px-3 py-5 items-center text-sm">
          {winPrizeImage ? (
            <>
              {winPrizeImage && <img src={winPrizeImage} className="mr-2" width="24" height="24" alt="Winning Token" />}
              <div className="crypto-price__name flex-1 text-sm font-display font-semibold">
                <span className="text-jacarta-700 dark:text-white mr-1">{winningAmount}</span>
                {!winPrizeImage && <span className="text-[10px]">{prizeType}</span>}
              </div>
            </>
          ) : (
            <span>
              {winningAmount} {prizeType}
            </span>
          )}
        </div>
      )}
      <div className="crypto-price__coin w-[25%] px-3 py-5 items-center text-sm">{dateTime}(UTC)</div>
    </div>
  )
}

export default RecentGameUserItem
