import Link from 'next/link'

const MiningItem = ({ hash, minner, quantity, steps, symbol, time, referralCode }) => {
  var image = undefined
  if (symbol === 'WKM') {
    image = 'https://ad-banner.walkmining.com/web/ic_coin_wkm.png'
  } else if (symbol === 'WKG') {
    image = 'https://ad-banner.walkmining.com/web/ic_coin_wkg.png'
  } else {
    undefined
  }

  return (
    <div className="flex crypto-price items-center">
      <div className="crypto-price__coin flex w-[25%] items-center px-3 py-5">
        <div className="crypto-price__name flex-1 text-sm font-display font-semibold">
          <span className="text-jacarta-700 dark:text-white mr-3">#{hash}</span>
        </div>
      </div>
      <div className="crypto-price__coin flex w-[25%] px-3 py-5 items-center text-sm cursor-pointer">
        <Link legacyBehavior href={`/user/${referralCode}`}>
          <span>@{minner.length > 10 ? `${minner.slice(0, 10)}...` : minner}</span>
        </Link>
      </div>
      <div className="crypto-price__coin flex w-[25%] px-3 py-5 items-center">
        {image && <img src={image} className="mr-2" width="24" height="24" alt="Mining Token" />}
        <div className="crypto-price__name flex-1 text-sm font-display font-semibold">
          <span className="block text-jacarta-700 dark:text-white mr-1 md:hidden">{quantity.toFixed(6)}</span>
          <span className="hidden text-jacarta-700 dark:text-white mr-1 md:block">{quantity}</span>
          {!image && <span className="text-[10px]">{symbol}</span>}
          <div className="text-[12px]">{steps} Steps</div>
        </div>
      </div>
      <div className="crypto-price__coin w-[25%] px-3 py-5 items-center text-sm">{time}(UTC)</div>
    </div>
  )
}

export default MiningItem
