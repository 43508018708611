import { useState } from 'react'
import { useTranslation } from 'next-i18next'
import MiningTableList from './MiningTableList'
import TableHeadLine from './TableHeadLine'
import { useInterval } from '../../utils/useInterval'
import { fetchGetRecentMining } from '../../utils/client/mining-api'
import { numberWithCommasNormal } from '../../utils/utils'

const index = ({ recentMiningList, totalMiningCount }) => {
  const [recentMinings, setRecentMinings] = useState(recentMiningList)

  const { t: tCommon } = useTranslation('common')

  async function getRecentMinings() {
    const miningResponse = await fetchGetRecentMining()
    if (miningResponse && miningResponse.status === 200 && miningResponse.data) {
      setRecentMinings(miningResponse.data)
    }
  }

  useInterval(() => {
    getRecentMinings()
  }, 10000)

  return (
    <>
      <section className="bg-white pt-10 dark:bg-jacarta-900">
        <div className="container">
          <TableHeadLine
            image="https://ad-banner.walkmining.com/web/mining_icon.png"
            text={tCommon('main.mining_title')}
            classes="font-display text-jacarta-700 mb-8 text-center text-3xl dark:text-white"
          />
          <div className="overflow-x-auto">
            <div className="text-left md:text-right text-xs mb-1 text-jacarta-400">
              {tCommon('main.mining_count_title')}:{' '}
              <span className="dark:text-jacarta-200">{numberWithCommasNormal(totalMiningCount)}</span>
            </div>
            <div className="rounded-lg bg-white min-w-[500px] dark:bg-jacarta-700 dark:text-jacarta-300 overflow-x-auto">
              <div className="flex items-center dark:border-jacarta-600 border-jacarta-100 border rounded-t-lg text-sm ">
                <div className="w-[25%] px-3 py-5">{tCommon('main.mining_table_hash')}</div>
                <div className="w-[25%] px-3 py-5 text-left">{tCommon('main.mining_table_minner')}</div>
                <div className="w-[25%] px-3 py-5 text-left">{tCommon('main.mining_table_mining')}</div>
                <div className="w-[25%] px-3 py-5 text-left">{tCommon('main.mining_table_time')}</div>
              </div>
              <MiningTableList recentMinings={recentMinings} />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default index
