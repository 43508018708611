/* eslint-disable react/no-unescaped-entities */
import React from 'react'
import { useTranslation } from 'next-i18next'
import { numberWithCommasNormal } from '../../utils/utils'

const Statistic = ({ serviceStatistic }) => {
  const { t: tCommon } = useTranslation('common')

  return (
    <div>
      <section className="relative py-12 lg:py-36">
        <picture className="pointer-events-none absolute inset-0 -z-10 after:absolute after:bottom-0 after:h-1/3 after:w-full after:bg-gradient-to-t after:from-[#010107]">
          <img src="/images/gradient_creative.jpg" alt="gradient" className="h-full w-full -scale-y-100" />
        </picture>
        <div className="container mx-auto -mt-26 lg:pb-32 text-center">
          <h2 className="mb-12 font-display text-5xl lg:text-[4rem] text-white">{tCommon('main.about_title')}</h2>
          <p className="mb-20 max-w-4xl text-3xl font-medium text-white lg:ml-36">{tCommon('main.about_desc')}</p>
          {serviceStatistic && (
            <div className="grid grid-cols-2 gap-8 md:grid-cols-4">
              <div>
                <div>
                  <span className="text-fill-transparent inline-block bg-gradient-to-r from-[#FFE993] to-[#FFB770] bg-clip-text font-display text-5xl font-semibold text-white">
                    {serviceStatistic.totalSteps}
                  </span>
                </div>
                <span className="text-lg text-white">Steps</span>
              </div>
              <div>
                <div>
                  <span className="text-fill-transparent inline-block bg-gradient-to-r from-[#FFE993] to-[#FFB770] bg-clip-text font-display text-5xl font-semibold text-white">
                    {serviceStatistic.minedWkm} <span className="text-[12px]">WKM</span>
                  </span>
                </div>
                <span className="text-lg text-white">Mining</span>
              </div>
              <div>
                <div>
                  <span className="text-fill-transparent inline-block bg-gradient-to-r from-[#FFE993] to-[#FFB770] bg-clip-text font-display text-5xl font-semibold text-white">
                    {numberWithCommasNormal(serviceStatistic.gamePlay)}
                  </span>
                </div>
                <span className="text-lg text-white">Game Play</span>
              </div>
              <div>
                <div>
                  <span className="text-fill-transparent inline-block bg-gradient-to-r from-[#FFE993] to-[#FFB770] bg-clip-text font-display text-5xl font-semibold text-white">
                    {numberWithCommasNormal(serviceStatistic.gameWinning)}
                  </span>
                </div>
                <span className="text-lg text-white">Game Win</span>
              </div>
            </div>
          )}
        </div>
      </section>
    </div>
  )
}

export default Statistic
