import Link from 'next/link'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'next-i18next'
import { getAvatar } from '../../utils/avatar'
import { fetchGetRecentMiningRank } from '../../utils/client/mining-api'

const TopMiningCollection = ({ bgWhite = false }) => {
  const [recentMiningRanks, setRecentMiningRanks] = useState([])
  // const [rankResponse, setRankResponse] = useState(undefined)

  const { t: tCommon } = useTranslation('common')

  useEffect(() => {
    getRecentRank()
  }, [])

  async function getRecentRank() {
    try {
      const response = await fetchGetRecentMiningRank()
      if (response && response.status === 200 && response.data) {
        // setRankResponse(response.data)

        const { todayRank, weekRank, monthRank } = response.data
        const currentTodayRank = {
          parentId: 1,
          name: tCommon('main.mining_rank_24h_title'),
          collections: todayRank.slice(0, 5),
        }
        const currentWeekRank = {
          parentId: 2,
          name: tCommon('main.mining_rank_7d_title'),
          collections: weekRank.slice(0, 5),
        }
        const currentMonthRank = {
          parentId: 3,
          name: tCommon('main.mining_rank_30d_title'),
          collections: monthRank.slice(0, 5),
        }
        setRecentMiningRanks([currentTodayRank, currentWeekRank, currentMonthRank])
      }
    } catch (e) {
      console.log(`Error`, e)
    }
  }

  return (
    <div>
      <section className="py-11 relative">
        {bgWhite && (
          <picture className="pointer-events-none absolute inset-0 -z-10 dark:hidden">
            <img src="/images/gradient_light.jpg" alt="gradient" className="h-full" />
          </picture>
        )}
        <div className="container">
          <div className="flex flex-col space-y-5 lg:flex-row lg:space-y-0 lg:space-x-7">
            {recentMiningRanks.map(({ parentId, name, collections }) => {
              return (
                <div className="dark:bg-jacarta-800 bg-light-base rounded-2.5xl p-12 lg:w-1/3" key={parentId}>
                  <h2 className="text-jacarta-700 font-display mb-8 text-center text-3xl font-semibold dark:text-white">
                    {name}
                  </h2>

                  <div className="flex flex-col space-y-5">
                    {collections.length > 0 ? (
                      collections.map((item, idx) => {
                        const { userRank, userName, referralCode, earnAmount, profileImage } = item
                        return (
                          <div
                            key={idx}
                            className="border-jacarta-100 dark:bg-jacarta-700 rounded-2xl flex border bg-white py-4 px-7 transition-shadow hover:shadow-lg dark:border-transparent"
                          >
                            <figure className="mr-4 shrink-0">
                              <Link legacyBehavior href={'/user/' + referralCode}>
                                <a className="relative block">
                                  <img src={getAvatar(profileImage)} alt={userName} className="rounded-2lg h-12 w-12" />
                                  {userRank > 3 && (
                                    <div className="dark:border-jacarta-600 bg-jacarta-700 absolute -left-3 top-1/2 flex h-6 w-6 -translate-y-2/4 items-center justify-center rounded-full border-2 border-white text-xs text-white">
                                      {userRank}
                                    </div>
                                  )}
                                  {userRank === 1 && (
                                    <div
                                      className="dark:border-jacarta-600 absolute -left-3 top-[60%] flex h-7 w-7 items-center justify-center rounded-full border-2 border-white"
                                      data-tippy-content="Verified Collection"
                                    >
                                      <img src="/images/ic_trophy.png" alt="Mining Rank no1" />
                                    </div>
                                  )}
                                  {userRank === 2 && (
                                    <div
                                      className="dark:border-jacarta-600 absolute -left-3 top-[60%] flex h-7 w-7 items-center justify-center rounded-full border-2 border-white"
                                      data-tippy-content="Verified Collection"
                                    >
                                      <img src="/images/ic_trophy_2.png" alt="Mining Rank no2" />
                                    </div>
                                  )}
                                  {userRank === 3 && (
                                    <div
                                      className="dark:border-jacarta-600 absolute -left-3 top-[60%] flex h-7 w-7 items-center justify-center rounded-full border-2 border-white"
                                      data-tippy-content="Verified Collection"
                                    >
                                      <img src="/images/ic_trophy_3.png" alt="Mining Rank no3" />
                                    </div>
                                  )}
                                </a>
                              </Link>
                            </figure>
                            <div>
                              <Link legacyBehavior href={'/user/' + referralCode}>
                                <a className="block">
                                  <span className="font-display text-jacarta-700 hover:text-accent font-semibold dark:text-white">
                                    @{userName}
                                  </span>
                                </a>
                              </Link>
                              <span className="dark:text-jacarta-300 text-sm">{earnAmount} WKM</span>
                            </div>
                          </div>
                        )
                      })
                    ) : (
                      <div className="text-center text-[#A1A2B3]">Analyzing...</div>
                    )}
                  </div>
                  <Link legacyBehavior className="cursor-pointer" href={`/ranking?type=mining&filter=${parentId}`}>
                    <span className="text-accent mt-8 block text-center text-sm font-bold tracking-tight cursor-pointer">
                      {tCommon('more_text')}
                    </span>
                  </Link>
                </div>
              )
            })}
          </div>
        </div>
      </section>
    </div>
  )
}

export default TopMiningCollection
