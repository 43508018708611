import { http, responseOk, responseError } from './axios-client'
import _ from 'lodash'

export async function fetchGetRecentGameRank(type, isSSR = false) {
  return await http(isSSR)
    .get(`explorer/game/rank?type=${type || 'CUMULATIVE_WINNING_AMOUNT'}`)
    .then((res) => responseOk(res))
    .catch((err) => responseError(err))
}

export async function fetchGetRecentGameWinners(size, isSSR = false) {
  return await http(isSSR)
    .get(`explorer/game/recent-winning-users?size=${size}`)
    .then((res) => responseOk(res))
    .catch((err) => responseError(err))
}

export async function fetchGetRecentGameParticipationUsers(size, isSSR = false) {
  return await http(isSSR)
    .get(`explorer/game/recent-participation-users?size=${size}`)
    .then((res) => responseOk(res))
    .catch((err) => responseError(err))
}
