import { useEffect, useState } from 'react'
import { useTranslation } from 'next-i18next'
import TableHeadLine from './TableHeadLine'
import { useInterval } from '../../utils/useInterval'
import { fetchGetRecentGameParticipationUsers, fetchGetRecentGameWinners } from '../../utils/client/game-api'
import RecentGameUserTableList from './RecentGameUserTableList'

const index = ({ mode, viewSize, moreBtn }) => {
  // mode: PARTICIPATIONS, WINNERS
  const [resultValues, setResultValues] = useState([])

  const { t: tCommon } = useTranslation('common')

  async function getRecentGameWinners() {
    const response = await fetchGetRecentGameWinners(viewSize, false)
    if (response && response.status === 200 && response.data) {
      setResultValues(response.data)
    }
  }

  async function getRecentGameParticipationUsers() {
    const response = await fetchGetRecentGameParticipationUsers(viewSize, false)
    if (response && response.status === 200 && response.data) {
      setResultValues(response.data)
    }
  }

  useEffect(() => {
    if (mode === 'PARTICIPATIONS') {
      getRecentGameParticipationUsers()
    } else {
      getRecentGameWinners()
    }
  }, [])

  useInterval(() => {
    if (mode === 'PARTICIPATIONS') {
      getRecentGameParticipationUsers()
    } else {
      getRecentGameWinners()
    }
  }, 10000)

  return resultValues.length >= 10 ? (
    <>
      <section className="bg-white pt-10 dark:bg-jacarta-900">
        <div className="container">
          <TableHeadLine
            text={mode == 'PARTICIPATIONS' ? 'Game Participants' : 'Game Winners'}
            classes="font-display text-jacarta-700 mb-8 text-center text-3xl dark:text-white"
          />
          <div className="overflow-x-auto">
            <div className="rounded-lg bg-white min-w-[500px] dark:bg-jacarta-700 dark:text-jacarta-300 overflow-x-auto">
              <div className="flex items-center dark:border-jacarta-600 border-jacarta-100 border rounded-t-lg text-sm ">
                <div className="w-[25%] px-3 py-5">#Game</div>
                <div className="w-[25%] px-3 py-5 text-left">User</div>
                {mode !== 'WINNERS' && <div className="w-[25%] px-3 py-5 text-left">Play</div>}
                {mode === 'WINNERS' && <div className="w-[25%] px-3 py-5 text-left">Reward</div>}
                <div className="w-[25%] px-3 py-5 text-left">Play Time</div>
              </div>
              <RecentGameUserTableList recentGameUsers={resultValues} moreBtn={moreBtn} />
            </div>
          </div>
        </div>
      </section>
    </>
  ) : (
    <div className="text-center">Loading...</div>
  )
}

export default index
