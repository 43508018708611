import { http, responseOk, responseError } from './axios-client'
import _ from 'lodash'

export async function fetchGetRecentMining(isSSR = false) {
  return await http(isSSR)
    .get(`explorer/mining/recent`)
    .then((res) => responseOk(res))
    .catch((err) => responseError(err))
}

export async function fetchGetRecentMiningRank(limit = 10, isSSR = false) {
  return await http(isSSR)
    .get(`explorer/mining/rank?limit=${limit}`)
    .then((res) => responseOk(res))
    .catch((err) => responseError(err))
}
