const TableHeadLine = ({ text, classes, image, pera }) => {
  return (
    <div>
      <h2 className={classes}>
        {image && (
          <img src={image} alt="headling" className={'mr-1 inline-block h-12 w-10 bg-contain bg-center text-xl pb-2'} />
        )}

        {text}
      </h2>
      {pera && <p className="dark:text-jacarta-300 mt-6"></p>}
    </div>
  )
}

export default TableHeadLine
