import Link from 'next/link'
import FadeIn from 'react-fade-in'
import { useTranslation } from 'next-i18next'
import RecentGameUserItem from './RecentGameUserItem'

function RecentGameUserTableList({ recentGameUsers, moreBtn }) {
  const { t: tCommon } = useTranslation('common')

  return recentGameUsers ? (
    <>
      <div className="divide-y divide-jacarta-100 dark:divide-jacarta-600">
        <FadeIn>
          {recentGameUsers.map((user, idx) => {
            return (
              <RecentGameUserItem
                key={idx}
                gameName={user.gameName}
                userName={user.userName}
                referralCode={user.referralCode}
                buyAmount={user.buyAmount}
                buyTokenType={user.buyTokenType}
                winningAmount={user.winningAmount}
                prizeType={user.prizeType}
                dateTime={user.dateTime}
              />
            )
          })}
        </FadeIn>
      </div>
      {moreBtn && (
        <div className="pb-6">
          <Link legacyBehavior className="cursor-pointer" href={`/game/recent-winners`}>
            <span className="text-accent mt-8 block text-center text-sm font-bold tracking-tight cursor-pointer">
              {tCommon('more_text')}
            </span>
          </Link>
        </div>
      )}
    </>
  ) : null
}
export default RecentGameUserTableList
